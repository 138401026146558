import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { SpinnerFacade } from "@cg/spinner";
import { TranslocoService } from "@jsverse/transloco";
import { JobApplication } from "../interfaces/job-application.interface";
import { JobApplicationService } from "../services/job-application.service";
import { JobApplicationTrackingService } from "../services/job-application-tracking.service";
import * as JobApplicationActions from "./job-application.actions";

@Injectable()
export class JobApplicationEffects {
  public readonly submitEventAction = "submit-application";

  public sendJobApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobApplicationActions.sendJobApplicationButtonClicked),
      switchMap((action: { jobApplication: JobApplication }) => {
        this.spinnerFacade.showSpinner({});
        return this.jobApplicationService.sendJobApplication(action.jobApplication).pipe(
          map(() => {
            this.jobApplicationTrackingService.trackSubmitApplicationEvent(false, action.jobApplication.files.length);
            this.spinnerFacade.hideSpinner();
            this.router.navigateByUrl("/kfz-mechaniker-autoglas/bw-absenden").then();
            return JobApplicationActions.jobApplicationDispatchSuccessResultReceived();
          }),
          catchError(() => {
            this.jobApplicationTrackingService.trackSubmitApplicationEvent(true, action.jobApplication.files.length);
            this.spinnerFacade.hideSpinner();
            return of(
              JobApplicationActions.setErrorToastMessage({
                message: this.translocoService.translate("jobApplication.errorToastTryAgain")
              })
            );
          })
        );
      })
    )
  );

  public constructor(
    private readonly actions$: Actions,
    private readonly jobApplicationService: JobApplicationService,
    private readonly router: Router,
    private readonly translocoService: TranslocoService,
    private readonly spinnerFacade: SpinnerFacade,
    private readonly jobApplicationTrackingService: JobApplicationTrackingService
  ) {}
}
